<template>
    <footer style="background: #ffffff;border-top: 5px solid #6677ef;" class="pt-5">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-5 mb-4">
                    <h4 class="font-weight-bold">Kona Ba</h4>
                    <hr style="border-top: 3px solid rgb(226 232 240);border-radius:.5rem">
                    <p>
                        LebookAux, Ler para ser livre. E ser livre para servir.
                    </p>
                    <div class="social">
                        <a href="https://www.facebook.com/arui.ximenes?mibextid=ZbWKwL" class="mr-4"> <i class="fab fa-facebook fa-2x"></i></a>
                        <a href="#" class="mr-4" style="color:#00c4ff"> <i class="fab fa-twitter fa-2x"></i></a>
                        <a href="#" class="mr-4" style="color: #de2fb8;"> <i class="fab fa-instagram fa-2x"></i></a>
                        <a href="#" style="color: red;"> <i class="fab fa-youtube fa-2x"></i></a>
                    </div>
                </div>
                <div class="col-md-3 mb-4">
                    <h4 class="font-weight-bold">Lokalizasaun</h4>
                    <hr style="border-top: 3px solid rgb(226 232 240);border-radius:.5rem">
                   <div class="row">
                    <div class="card-body">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3945.505055711271!2d125.53963201131953!3d-8.547334986615462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2d01ddca905ca80d%3A0xf315217204f84a70!2sLebook%20Aux%20Bookstore!5e0!3m2!1sid!2stl!4v1724983231300!5m2!1sid!2stl"
                    width="100%"
                    height="200"
                    frameborder="0"
                    style="border:0;"
                    allowfullscreen=""
                    aria-hidden="true"
                    tabindex="0"
                  ></iframe>
                </div>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <h4 class="font-weight-bold">KONTAK</h4>
                    <hr style="border-top: 3px solid rgb(226 232 240);border-radius:.5rem">
                    <p>
                        <i class="fa fa-clock"></i> Lebookaux Store Loke Loron-Loron :
                        <br>
                        Segunda - Domingo ( 07.00 s/d 16.00 )
                    </p>
                    <p>
                        <i class="fa fa-map-marker"></i> 20 de Setembro, Bebonuk,Dili,Timor-Leste
                    </p>
                </div>
            </div>
            <div class="row text-center mt-3 pb-3">
                <div class="col-md-12">
                    <hr>
                    © <strong>Lebookaux </strong> 2020 • Copy@right Ximenes92
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'FooterComponent',
    }
</script>