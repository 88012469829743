<template>
    <header class="section-header">
        <section class="header-main border-bottom">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-md-3 col-7">
                        <router-link :to="{name: 'home'}" class="text-decoration-none" data-abc="true">
<span class="logo" style="color: black;">
                    <img src="../assets/logo.png" alt="LEBOOKAUX STORE Logo" style="width: 30px; height: 30px;">
  LEBOOKAUX STORE
</span>
                        </router-link>
                    </div>
                    <div class="col-md-5 d-none d-md-block">
                        <form class="search-wrap">
                            <div class="input-group w-100"><input type="text" class="form-control search-form"
                                    style="width:55%;border: 1px solid #ffffff" name="q"
                                    placeholder="Hakarak hola product saida ohin loron bele share naran iha nee ?">
                                <div class="input-group-append">
                                    <button class="btn search-button" type="submit"><i class="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-4 col-5">
                        <div class="d-flex justify-content-end">

                           

                          

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </header>
</template>

<script>
    import { computed, onMounted } from 'vue'
    import { useStore } from 'vuex'

    export default {

        name: 'HeaderComponent',

        setup() {

            //store vuex
            const store = useStore()

            

            //cart count
            const cartCount = computed(() => {

                //get getter "cartCount" dari module "auth"
                return store.getters['cart/cartCount']
            })

            //cart total
            const cartTotal = computed(() => {

                //get getter "cartTotal" dari module "auth"
                return store.getters['cart/cartTotal']
            })

            //mounted
            onMounted(() => {

                //check state token
                const token = store.state.auth.token

                if(!token) {
                    return
                }

                //saat mounted, akan memanggil action "cartCount" di module "cart"
                store.dispatch('cart/cartCount')

                //saat mounted, akan memanggil action "cartTotal" di module "cart"
                store.dispatch('cart/cartTotal')
            })


            return {
                store,
                
                cartTotal,
                cartCount
            }

        }

    }
</script>